angular.module('enervexSalesappApp').controller('ImageCropperController', function($scope, $uibModalInstance, _, file, grayFile, Cropper, $timeout) {
	var data;
	$scope.isGrayScale = false

	function toDataUrl(useGray) {
		var loadFile = file
		if (useGray) {
			console.log('should be using gray file')
			loadFile = grayFile
		}
		Cropper.encode(loadFile).then(function(dataUrl) {
			$scope.dataUrl = dataUrl;
			$timeout(hideCropper);
            $timeout(showCropper);
		})
	}

	$scope.toggleGrayScale = function() {
		$scope.isGrayScale = !$scope.isGrayScale
		toDataUrl($scope.isGrayScale)
	}

	toDataUrl($scope.isGrayScale)

	$scope.cropper = {}
	$scope.copperProxy = 'cropper.first'
	$scope.options = {
		maximize: false,
		// aspectRatio: 2 / 1,
		crop: function(dataNew) {
			data = dataNew;
		},
		viewMode: 0,
		guides: true,
		movable: false,
		cropBoxMovable: true,
		dragMode: 'crop',
		scalable: false,
		zoomable: false
	};
	$scope.preview = function() {
		if (!file || !data) return;
		Cropper.crop(file, data).then(Cropper.encode).then(function(dataUrl) {
			($scope.preview || ($scope.preview = {})).dataUrl = dataUrl;
		});
	};

	$scope.clear = function(degrees) {
		if (!$scope.cropper.first) return;
		$scope.cropper.first('clear');
	};

	$scope.scale = function(width) {
		console.log("scaling", width)
		Cropper.crop(file, data).then(function(blob) {
			return Cropper.scale(blob, {
				width: width
			});
		}).then(Cropper.encode).then(function(dataUrl) {
			($scope.preview || ($scope.preview = {})).dataUrl = dataUrl;
		});
	}
	$scope.showEvent = 'show';
	$scope.hideEvent = 'hide';

	function showCropper() {
		$scope.$broadcast($scope.showEvent);
	}

	function hideCropper() {
		$scope.$broadcast($scope.hideEvent);
	}

	$scope.ok = function() {
		var loadFile = file;
		if ($scope.isGrayScale) {
			loadFile = grayFile;
		}
		hideCropper();
		Cropper.crop(loadFile, data).then(function (blob) {
			var modalInstance = $uibModalInstance.close({
				cropped: blob
			});
		});
	}
});